// Copright (c) 2019 Greatery Development Team
// Distributed under the terms of the Apache License 2.0

import React from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"


export default function Contact(props) {

    return (
        <div>
            <p>Contact us soon.</p>
        </div>
    )

}
